import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
// import api from '../../../services/API';
import { toast } from 'react-toastify';
import axios from 'axios';
import './styles.css';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';

export default function Perfil() {
  const atualizando = useSelector((state) => state.parceiro.atualizando);
  const dispatch = useDispatch();

  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const atualizarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      nome,
      sexo,
      dataNascimento,
      email,
      telefone,
      celular,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      senhaAntiga,
      senha,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
    data.dataNascimento = `${data.dataNascimento.substring(
      4
    )}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(
      0,
      2
    )}`;

    dispatch(atualizarPerfil(data));
  };

  const [rut] = useState(parceiro.rut);
  const [nome, setNome] = useState(parceiro.nome);
  const [sexo, setSexo] = useState(parceiro.sexo);
  const [dataNascimento, setDataNascimento] = useState(
    `${parceiro.dataNascimento.slice(8)}${parceiro.dataNascimento.slice(
      5,
      7
    )}${parceiro.dataNascimento.slice(0, 4)}`
  );
  const [email, setEmail] = useState(parceiro.email);
  const [telefone, setTelefone] = useState(parceiro.telefone);
  const [celular, setCelular] = useState(parceiro.celular);
  const [endRua, setRua] = useState(parceiro.endRua);
  const [endNumero, setNumero] = useState(parceiro.endNumero);
  const [endComplemento, setComplemento] = useState(parceiro.endComplemento);
  const [endBairro, setBairro] = useState(parceiro.endBairro);
  const [endCidade, setCidade] = useState(parceiro.endCidade);
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senha, setSenha] = useState('');

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Género obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'senha' &&
        param[0] !== 'senhaAntiga' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento?.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });
    return temNulo;
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>MI PERFIL</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <form
          onSubmit={atualizarParceiro}
          className="form loja fundo-claro justify-content-center align-items-center"
        >
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="rut">RUC</label>
              <InputMask
                id="rut"
                name="rut"
                type="text"
                placeholder="RUC"
                value={rut}
                disabled
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="nome">Nombre</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nombre"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="dataNascimento">Fecha de Nascimiento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                placeholder="Fecha de Nascimiento"
                value={dataNascimento}
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="sexo">Género</label>
              <select
                id="sexo"
                name="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                placeholder="Género"
              >
                <option disabled value="">
                  Seleccione género
                </option>
                <option value="F">Mujer</option>
                <option value="M">Masculino</option>
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="celular">Teléfono móvil</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                mask="(999) 999-999999"
                placeholder="Teléfono móvil"
                value={celular}
                onChange={(e) => setCelular(e.target.value)}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6} md={3}>
              <label htmlFor="telefone">Teléfono</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Teléfono"
                value={telefone}
                mask="(999) 999-999999"
                onChange={(e) => setTelefone(e.target.value)}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="nome">Correo Electrónico</label>
              <input
                id="email"
                name="email"
                type="text"
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endRua">Dirección</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Dirección"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
            {/* <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endBairro">Urbanización</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Urbanización"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col> */}
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endCidade">Ciudad</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Ciudad"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="senhaAntiga">Contraseña antigua</label>
              <input
                id="senhaAntiga"
                name="senhaAntiga"
                type="password"
                placeholder="Dejar en blanco para no cambiar"
                value={senhaAntiga}
                onChange={(e) => setSenhaAntiga(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="senha">Nueva contraseña</label>
              <input
                id="senha"
                name="senha"
                type="password"
                placeholder="Dejar en blanco para no cambiar"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              ></input>
            </Col>
          </Row>
          {atualizando ? (
            <button disabled className="btn-atualizar" type="submit">
              ACTUALIZACION...
            </button>
          ) : (
            <button className="btn-atualizar" type="submit">
              ACTUALIZAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
