import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import { Link } from 'react-router-dom';

export default function Home() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  useEffect(() => {
    document.title = 'Compañero Chamex ';
  }, []);

  const solicitarAutenticacao = ({ rut, senha }) => {
    dispatch(autenticarParceiro(rut, senha));
  };

  return (
    <>
      <Container className="flex-column mw-100 m-0 p-0">
        <Row className="m-0">
          <Link to="/login">
            <img
              className=" w-100"
              src="imagens/banner.jpg"
              alt="Compañero Chamex"
            ></img>
          </Link>
        </Row>

        <Row className="justify-content-center mt-5 mb-5">
          <Form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5>INICIAR SESIÓN</h5>
            <label htmlFor="rut">RUC</label>
            <Input id="rut" name="rut" type="text" placeholder="RUC"></Input>
            <label htmlFor="senha">Contraseña</label>
            <Input
              name="senha"
              type="password"
              placeholder="Contraseña"
            ></Input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                INGRESSAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </Form>
        </Row>
      </Container>

      <Footer />
    </>
  );
}
