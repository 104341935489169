import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import InputMask from 'react-input-mask';
import SelectRevenda from '../SelectRevenda/';
import historico from '../../../services/Historico';
import api from '../../../services/API';

export default function CadastrarParceiro() {
  const [enviando, setEnviando] = useState(false);
  const [rut, setRut] = useState('');
  const [cnpjRevenda, setCnpjRevenda] = useState('');
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endCidade, setCidade] = useState('');

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'Género') {
        toast.error(`Género obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'email'
      ) {
        console.log(param);
        console.log(param);
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento?.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const buscarRut = async (e) => {
    const newRut = e.target.value.replace(/[^\d]/g, '');
    if (!newRut) return;
    const resultado = await api.get(`/api/parceiros?rut=${newRut}`);
    if (resultado.data.parceiros.length > 0) {
      toast.error('Vendedor ya registrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setRut('');
    }
  };


  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  const cadastrarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      cnpjRevenda: cnpjRevenda.value,
      nome,
      rut,
      telefone,
      celular,
      email,
      dataNascimento,
      sexo,
      endRua,
      endNumero,
      endComplemento,
      endCidade,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    data.cnpjRevenda = data.cnpjRevenda.replace(/[^\d]/g, '');
    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.rut = data.rut;
    data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
    data.dataNascimento = `${data.dataNascimento.substring(
      4
    )}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(
      0,
      2
    )}`;

    setEnviando(true);
    const resultado = await api.post('/api/parceiros', data);

    if (resultado) {
      toast.success('Vendedor  registrado con éxito', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/painel/cadastrar/parceiro');
    }
    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Registrar vendedor Mayorista</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarParceiro}>
          <label htmlFor="revenda">Mayorista</label>
          <SelectRevenda id="revenda" onChange={setCnpjRevenda} />
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="RUC">RUC</label>
              <InputMask
                id="rut"
                name="rut"
                type="text"
                placeholder="RUC"
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                onBlur={buscarRut}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="dataNascimento">Fecha de nacimiento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                placeholder="Fecha de nacimiento"
                value={dataNascimento}
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
            </Col>
          </Row>

          <label htmlFor="nome">Nombre</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nombre"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          ></input>

          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="sexo">Género</label>
              <select
                id="sexo"
                name="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                placeholder="Género"
              >
                <option disabled value="">
                  Seleccione género
                </option>
                <option value="F">Mujer</option>
                <option value="M">Masculino</option>
              </select>
            </Col>
          </Row>
          <label htmlFor="nome">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>

          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="celular">Teléfono móvil</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Teléfono móvil"
                value={celular}
                mask="(999) 999-999999"
                onChange={(e) => setCelular(e.target.value)}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="telefone">Teléfono</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Teléfono"
                value={telefone}
                mask="(999) 999-999999"
                onChange={(e) => setTelefone(e.target.value)}
              ></InputMask>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="endRua">Dirección</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Dirección"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="endCidade">Ciudad</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Ciudad"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={12}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
          </Row>

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              INSCRIBIRSE...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              REGISTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
