import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';

const columns = [
  {
    dataField: 'codigo',
    text: 'Código',
  },
  {
    dataField: 'nome',
    text: 'Producto',
  },
  {
    dataField: 'valorPontos',
    text: 'Puntos',
    formatter: (celula, valor) => {
      return Number(valor.valorPontos).toLocaleString('pt-BR');
    },
    sort: true,
    sortFunc: sortFunc,
  },
];

const defaultSorted = [
  {
    dataField: 'valorPontos',
    order: 'desc',
  },
];

export default function ListaPontos() {
  const cnpjRevenda = useSelector((state) => state.parceiro.cnpjRevenda);

  useEffect(() => {
    buscarProdutos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarProdutos = async () => {
    const resultado = await api.get(
      `/api/produtos_revenda?cnpjRevenda=${cnpjRevenda}`
    );
    if (resultado) {
      setData(resultado.data.produtosRevenda);
    }
  };

  const [data, setData] = useState([]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>PUNTOS POR PRODUCTO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          className="tabela-fundo-claro"
          keyField="codigo"
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          pagination={paginationFactory({ sizePerPage: 50 })}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
          bootstrap4
        />
      </div>
    </Container>
  );
}
