import React from 'react';
import { Container } from 'react-bootstrap';
import './style.css';

export default function Regulamento() {
  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>REGLAMENTO COMPAÑERO CHAMEX</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="text-justify">
        <div>
          <p>
            “Compañero Chamex ” es un Programa de Incentivo para Vendedores
            Registrados, en el cual cada producto de la marca Chamex vendido
            tiene un valor de puntos específico, que podrá ser alterado a
            cualquier momento.
          </p>
          <p>
            El vendedor de la Mayorista registrado acumulará puntos y los
            cambiará por premios, de acuerdo con las reglas de participación y
            puntuación descritas en este documento.
          </p>
          <p>
            El presente Programa “Compañero Chamex” es desarrollado y realizado
            por Smart Tecnología em Vendas S/A, inscrita con el CNPJ nº
            20.893.968/0001-00, denominada “Smart”, y promovida por SYLVAMO,
            inscrita con el CNPJ nº 48.708.010/0001-02, denominada “SYLVAMO”.
          </p>
          <p>
            Todas las reglas de puntuación, canjes y validad, podrán ser
            actualizadas sin previo aviso, siendo incluidas en este documento y
            comunicado a los participantes de forma clara. La preselección de
            las reventas participantes es de responsabilidad de SYLVAMO.
          </p>
          <p>
            El participante, al registrar en el Programa, acepta y está de
            acuerdo con las reglas siguientes.
          </p>
        </div>
        <br />
        <div>
          <ol>
            <li>
              <span>REGLAMENTO</span>
              <ol>
                <li>
                  <span>
                    El presente Programa será realizado conforme reglas
                    descritas en este Reglamento.
                  </span>
                </li>
                <li>
                  <span>
                    Este Programa es direccionado exclusivamente a los miembros
                    de Compañero Chamex registrados en los establecimientos
                    afiliados, siendo todos personas físicas y mayores de 18
                    (dieciocho) años, que tengan el hábito de relacionarse con
                    la empresa promotora.
                  </span>
                </li>
                <li>
                  <span>
                    El Compañero Chamex es destinado exclusivamente a los
                    vendedores de Mayoristaes participantes, no siendo permitida
                    la participación de propietarios.
                  </span>
                </li>
                <li>
                  <span>
                    Los datos entregados por las Mayoristaes o participantes que
                    no sean reales, automáticamente serán eliminados y los
                    puntos expirados, pudiendo extender la eliminación y
                    expiración de los puntos de todos los participantes de la
                    Mayorista.
                  </span>
                </li>
                <li>
                  <span>
                    El registro de miembros de Compañero Chamex deberá ser
                    efectuado a través del Equipo de apoyo al miembro del
                    programa, a través del Formulario de Registro, donde la
                    contraseña inicial será 1234, debiendo ser alterada a través
                    del menú MI CUENTA.
                  </span>
                </li>
                <li>
                  <span>
                    Nunca entregue la contraseña de acceso al site
                    www.companerochamex.com a terceros, la contraseña es de
                    total responsabilidad del vendedor de la Mayorista
                    registrada. Entre al área Mi Cuenta en el panel o por el
                    link:
                    <a target="_blank" href={`/perfil`}>
                      {' '}
                      www.companerochamex.com/perfil{' '}
                    </a>
                    y altere su contraseña inicial. En caso de dudas o pérdida,
                    entre en contacto con el Equipo Compañero Chamex a través
                    del teléfono (595) 991 417369 o por el email:
                    contacto@companerochamex.com.
                  </span>
                </li>
                <li>
                  <span>
                    La finalidad de este Programa es generar relacionamiento y
                    compromiso de los Vendedores Mayoristaes con los productos
                    de Compañero Chamex. Los Vendedores de las Mayoristaes
                    registrados serán puntuados a través de los productos
                    vendidos dentro del Establecimiento registrado, de acuerdo
                    con las cuantidades adquiridas de Compañero Chamex
                  </span>
                </li>
                <li>
                  <span>
                    Los productos válidos para puntuación son los productos
                    vendidos pela Compañero Chamex, de la marca Compañero
                    Chamex.
                  </span>
                </li>
                <li>
                  <span>
                    Los socios registrados serán denominados e identificados
                    como Miembros de Compañero Chamex , siendo recompensados,
                    eventualmente, desde que hayan cumplidas las condiciones
                    descritas en el presente reglamento.
                  </span>
                </li>
                <li>
                  <span>
                    Smart ejecutará el Compañero Chamex directamente a los
                    Vendedores de Mayoristaes comerciales indicados y
                    registrados para el Programa de Relacionamiento por
                    Compañero Chamex.
                  </span>
                </li>
                <li>
                  <span>
                    En el caso de que la Mayorista del vendedor registrado
                    estuviera con deudas con Compañero Chamex, los premios no
                    podrán ser entregados y el Punto de Venda puede ser excluido
                    del Programa.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGLAS DE PUNTUACIÓN</span>
              <ol>
                <li>
                  <span>
                    El miembro de Compañero Chamex , deberá enviar una foto de
                    la Nota Fiscal / Cupón Fiscal por el{' '}
                    <a target="_blank" href="/">
                      www.companerochamex.com
                    </a>{' '}
                    para que la puntuación sea realizada y confirmada.
                  </span>
                </li>
                <li>
                  <span>
                    La Foto de Nota Fiscal/Cupón Fiscal debe ser enviada de
                    forma nítida, legible e inequívoca a la cantidad y
                    descripción de los productos, número de nota/cupón fiscal o
                    recibo y fecha de compra. Para todos los efectos, solamente
                    se aceptarán comprobantes válidos y legibles oriundos de las
                    tiendas participantes del Programa.
                  </span>
                </li>
                <li>
                  <span>
                    En caso de puntuación automática, los puntos serán
                    computados de acuerdo con la periodicidad previamente
                    acordada con su reventa, pudiendo ser diaria, semanal,
                    quincenal o mensual. Los puntos obtenidos por los
                    participantes tienen validade de 365 (trecientos y sesenta y
                    cinco) días, desde que la cuenta permanezca activa.
                  </span>
                </li>
                <li>
                  <span>
                    Los puntos obtenidos por los participantes tienen una
                    validad de 365 (trecientos y sesenta y cinco) días, desde
                    que la cuenta permanezca activa
                  </span>
                </li>
                <li>
                  <span>
                    De la misma forma, caso el participante no alcance la
                    cantidad suficiente de puntos para el cambio de premios en
                    el site{' '}
                    <a target="_blank" href="/">
                      www.companerochamex.com
                    </a>
                    , los puntos serán expirados, siguiendo los plazos encima
                    indicados.
                  </span>
                </li>
                <li>
                  <span>
                    El extracto de puntos podrá ser consultado en el site:
                    www.companerochamex.com, en el menú PUNTUACIONES, juntamente
                    con el nombre de usuario (login: número de RUC) y
                    contraseña.
                  </span>
                </li>
                <li>
                  <span>
                    Las puntuaciones son validadas directamente con el volumen
                    de compras realizadas por estabelecimiento con Compañero
                    Chamex.
                  </span>
                </li>
                <li>
                  <span>Los puntos son personales e intransferibles.</span>
                </li>
                <li>
                  <span>
                    Smart se reserva el derecho de revisión de los puntos hasta
                    90 (noventa) días después de la fecha de puntuación, para
                    revisión y corrección si fuera necesario.
                  </span>
                </li>
                <li>
                  <span>
                    Los puntos obtenidos a lo largo de este Programa podrán ser
                    utilizados de una única vez o de forma fragmentada.
                  </span>
                </li>
                <li>
                  <span>
                    En la eventualidad de algún miembro participante ser
                    despedido del establecimiento registrado, el saldo de puntos
                    permanecerá activo, respetando las reglas de validad de la
                    puntuación
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGLAS DE CANJES DE PREMIOS</span>
              <ol>
                <li>
                  <span>
                    Está disponible en el site de Compañero Chamex el CATÁLOGO
                    DE PREMIOS conteniendo los productos, que podrán ser
                    obtenidos por los socios a partir del uso de su crédito de
                    PUNTOS vigentes, en los términos de este REGLAMENTO y de
                    otras reglas disponibles en el site.
                  </span>
                </li>
                <li>
                  <span>
                    Compañero Chamex podrá alterar, sustituir o cancelar los
                    premios disponibles.
                  </span>
                </li>
                <li>
                  <span>
                    Los canjes de premios deberán ser realizados a través de el
                    site
                    <a target="_blank" href="/">
                      www.companerochamex.com
                    </a>
                    , mediante el uso de nombre de usuario y contraseña y saldo
                    suficiente para o ítem escogido.
                  </span>
                </li>
                <li>
                  <span>
                    En el caso de falla del sistema o en casos que el producto
                    sea canjeado fuera de las reglas de puntuación vigente, los
                    canjes serán automáticamente cancelados.
                  </span>
                </li>
                <li>
                  <span>
                    Después del canje solicitado en el site descrito encima, el
                    miembro de Compañero Chamex recibirá un e-mail y el equipo
                    de apoyo al vendedor Mayorista entrará en contacto a través
                    del teléfono de registro se fuera necesario.
                  </span>
                </li>
                <li>
                  <span>
                    El premio canjeado se entregará para el vendedor Mayorista
                    en el establecimiento registrado o en la dirección
                    registrada del miembro del Programa en hasta 20 días útiles,
                    la entrega será hecha mediante Formulario de Canje, válido
                    como recibo firmado, o confirmación de la transportadora.
                  </span>
                </li>
                <li>
                  <span>
                    El cambio de productos tendrá lugar únicamente cuando se
                    deba a la discrepancia comprobada entre lo solicitado y lo
                    entregado. El Usuario deberá solicitar el reemplazo del
                    producto dentro de los 5 (cinco) días hábiles siguientes a
                    la fecha de la entrega del mismo. El participante deberá
                    comunicarse por escrito con SMART, dentro de los 5 (cinco)
                    días hábiles siguientes a la entrega, vía WhatsApp al (595)
                    991 417369 o por correo electrónico a
                    contacto@companerochamex.com, describiendo el motivo de la
                    solicitud con detalles como:
                    <ul>
                      <li> 1. Nombre del participante </li>
                      <li> 2. RUC del participante</li>
                      <li> 3. Teléfono del Participante </li>
                      <li> 4. Fecha de recepción del producto</li>
                      <li>
                        5. Motivo de insatisfacción o simple indicación de que
                        es motivo de arrepentimiento;
                      </li>
                    </ul>
                    Compañero Chamex puede contactar al participante y entender
                    el motivo de la solicitud, a fin de revertir el proceso para
                    una mejor solución para el participante.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES</span>
              <ol>
                <li>
                  <span>
                    Los datos personales serán colectados por Smart con la
                    finalidad de administración y operación de PROGRAMA,
                    incluyendo el uso para los fines de: (i) registro junto al
                    PROGRAMA y contacto con el Miembro de Compañero Chamex ;
                    (ii) acumulación de PUNTOS; (iii) entrega de premiaciones;
                    (iv) optimización del catálogo de premios; (v)
                    desenvolvimiento de inteligencia de mercado, parámetros y
                    perfil de consumo, así como otras actividades asociadas al
                    buen funcionamiento y desarrollo del PROGRAMA.
                  </span>
                </li>
                <li>
                  <span>
                    Los datos serán almacenados en bancos de datos exclusivos
                    del Programa, em ambiente seguro.
                  </span>
                </li>
                <li>
                  <span>
                    SMART no compartirá los datos personales aquí mencionados,
                    excepto con el propio Compañero Chamex South América.
                  </span>
                </li>
                <li>
                  <span>
                    COMPAÑERO CHAMEX SOUTH AMERICA garantiza que los datos
                    recibidos serán utilizados para análisis de mercado y
                    reenvío de publicidad sobre productos de la marca Compañero
                    Chamex.
                  </span>
                </li>
                <li>
                  <span>
                    Compañero Chamex garantiza que los datos recibidos serán
                    almacenados en ambiente seguro y con acceso restricto
                    solamente a empleados de la empresa, todos obligado en
                    contrato a mantenerlos en protección y confidencialidad.
                  </span>
                </li>
                <li>
                  <span>
                    Compañero Chamex garantiza también que los datos serán
                    compartidos con empresas proveedoras de servicio de
                    almacenamiento y gerenciamiento de datos, todas obligadas
                    por contrato a mantenerlos en seguridad y confidencialidad.
                  </span>
                </li>
                <li>
                  <span>
                    Cuando fuera muy necesario, a Compañero Chamex podrá
                    compartir los datos recibidos con prestadores de servicio,
                    para mejorar el atendimiento de la empresa a sus clientes,
                    así como con los asesores jurídicos, para entrar o defensa
                    en acciones administrativas o judiciales. Todos obligados
                    por contrato a mantener la confidencialidad de las
                    informaciones. Podrá compartir también para el cumplimiento
                    de determinación de autoridad judicial o administrativa
                    competente. El Miembro de Compañero Chamex podrá a cualquier
                    momento corregir datos incompletos, no exactos o
                    desactualizados, informados en registro, por medio del menú
                    “MI CUENTA” después de registrar el usuario.
                  </span>
                </li>
                <li>
                  <span>
                    Cualquier momento, el miembro de Compañero Chamex puede
                    solicitar la exclusión total de sus datos.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>CONSIDERACIONES GENERALES</span>
              <ol>
                <li>
                  <span>
                    En el primer acceso, el Miembro de Compañero Chamex debe
                    hacer su ACEPTACIÓN a este REGLAMENTO, formalizando, por
                    tanto, su decisión de participar del PROGRAMA, declarando
                    ter amplio conocimiento y concordar integralmente, de manera
                    plena e irrestricta, con todos los términos y condiciones de
                    Compañero Chamex, estando apto a usar los beneficios del
                    PROGRAMA que le fueran aplicables. En el Caso de que el
                    ACEPTAR no sea efectivado, el acceso de Compañero Chamex no
                    estará apto para el uso.
                  </span>
                </li>
                <li>
                  <span>
                    La participación en este Programa implica la aceptación
                    total de todos os ítems de este Reglamento, que podrá ser
                    alterado por Smart tantas veces cuanto sea necesario, a su
                    exclusivo criterio.
                  </span>
                </li>
                <li>
                  <span>
                    A lo largo de este Programa, Smart irá a comunicarse con el
                    público-objetivo, de forma próxima y directa, utilizando los
                    principales canales de comunicación.
                  </span>
                </li>
                <li>
                  <span>
                    El Programa, por exclusivo criterio de Smart, podrá ser
                    interrumpido o encerrado em cualquier momento.
                  </span>
                </li>
                <li>
                  <span>
                    Serán automáticamente desclasificados los participantes que
                    no cumplan los ítems del presente reglamento, actuaren de
                    mala fe o que, de alguna forma, burlen las reglas y
                    condiciones de este Reglamento o utilicen mecanismos
                    fraudulentos o ilícitos.
                  </span>
                </li>
                <li>
                  <span>
                    Al participar de este Programa, el vendedor de la Mayorista
                    está de acuerdo, plenamente, con el uso de su nombre e
                    imagen, para divulgación de esta acción, en todos los medios
                    de comunicación, por el plazo máximo de 3 (tres) años
                    contado a partir de la fecha de registro, sin que esto
                    traiga cualquier tipo de carga para la empresa Smart o para
                    la Promotora.
                  </span>
                </li>
                <li>
                  <span>
                    La participación en Compañero Chamex es voluntaria,
                    facultativa y gratuita, no habiendo cualquier tipo de
                    sanción a aquellos que opten por no participar; y las dudas
                    relativas a este Programa podrán ser solucionadas de Lunes a
                    Viernes (excepto feriados) de las 9hs hasta las 18shs, a
                    través de los canales de comunicación:
                    <br />
                    <br />
                    Hable con nosotros:
                    <br />
                    WhatsApp: (595) 991 417369
                    <br />
                    Telefone: (595) 991 417369
                    <br />
                    E-mail: contacto@companerochamex.com
                  </span>
                </li>
              </ol>
            </li>
            <br />
            {/* 
            <li>
              <span>UTILIZACIÓN DE LA TARJETA</span>
              <ol>
                <li>
                  <span>
                  En el Primer canje en dinero, usted recibirá su tarjeta Compañero Chamex . Los próximos canjes en dinero, 
                  serán cargados automáticamente en esta misma tarjeta. Cada Vendedor tendrá apenas 1 (una) tarjeta, es válido solamente 1 por RUC.
                  </span>
                </li>
                <li>
                  <span>
                   Utilice siempre su TARJETA MASTERCARD® 
                   Compañero Chamex  en la función CRÉDITO.
                  </span>
                </li>
                <li>
                  <span>
                    Con la tarjeta en manos, usted puede hacer el desbloqueo em 
                    <a href="www.companerochamex.com/cartao ">
                      www.companerochamex.com/cartao
                    </a>
                      o por el teléfono 15 99731-7809 de lunes a viernes de las 9h hasta las 18h.
                  </span>
                </li>
                <li>
                  <span>
                   Para DESBLOQUEO por el site, el usuario necesita entrar en el sistema con su CPF y contraseña.
                  </span>
                </li>
                <li>
                  <span>
                   El plazo de la operadora para DESBLOQUEIO de su tarjeta es de 24h útiles después de haber solicitado.
                  </span>
                </li>
                <li>
                  <span>
                  En caso de pérdida o robo de la tarjeta, solicite el BLOQUEO em 
                    <a href="www.companerochamex.com/cartao ">
                      www.companerochamex.com/cartao
                    </a>
                      o por el teléfono 15 99731-7809 de lunes a viernes de las 9h hasta las 18h.
                  </span>
                </li>
                <li>
                  <span>
                   El plazo de la operadora para BLOQUEO de su tarjeta es de 24h útiles después haber solicitado.
                  </span>
                </li>
                <li>
                  <span>
                   Para alterar la contraseña, el usuario será direccionado para el site de la operadora de tarjeta 
                   donde por seguridad, será necesario completar los datos personales y hacer un registro en el panel de control.
                  </span>
                </li>
                <li>
                  <span>
                  Su contraseña es personal e intransferible. Usted puede alterar la contraseña impresa en 
                  la carta que acompaña su tarjeta en cualquier momento, dando un clic aquí.
                  </span>
                </li>
                <li>
                  <span>
                  El Programa Compañero Chamex , no cobra tasas o puntos para las operaciones 
                  hechas dentro del panel de usuario en el site de la operadora. Las tasas son 
                  cobradas por la operadora de la tarjeta y constan en este reglamento.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>SERVICIOS E TASAS DE LA OPERADORA DE LA TARJETA </span>
              <ol>
                <li>
                  <span>
                    El portador de la tarjeta pode utilizar los siguientes servicios en el panel de la operadora:
                    <br />
                    TRANSFERENCIA BANCARIA.
                    <br />
                    CRÉDITOS EN EL CELULAR.
                    <br />
                    PAGOS DE CUENTA.
                    <br />
                    CONSULTA DE SALDO Y EXTRACTO DE LA TARJETA.
                    <br />
                    ALTERACIÓN DE CONTRASEÑA.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TRANSFERENCIA BANCARIA</span>
              <ol>
                <li>
                  <span>El Usuario deberá registrar una cuenta en su nombre.</span>
                </li>
                <li>
                  <span>
                    No es permitido transferencia para cuenta de terceros.
                  </span>
                </li>
                <li>
                  <span>
                  Solamente después de la confirmación del código SMS y validación 
                  de saldo de su tarjeta es que la transferencia será agendada.
                  </span>
                </li>
                <li>
                  <span>
                   Solicitaciones efectuadas HASTA las 15:00 horas serán enviadas al banco en el mesmo día.
                  </span>
                </li>
                <li>
                  <span>
                  Solicitaciones efectuadas DESPUÉS de las 15:00 horas serán enviadas al banco el 
                  próximo día útil. TED - Será registrado en la cuenta corriente en el mismo día. 
                  DOC para conta de ahorros - Será registrado en la cuenta en el día siguiente.
                  </span>
                </li>
                <li>
                  <span>Tarifa para Transferencia: Gs 5,50.</span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>COLOCAR CRÉDITOS EN EL CELULAR</span>
              <ol>
                <li>
                  <span>
                   El premiado puede registrar su teléfono o de otras personas para adicionar saldo al celular. 
                   Sin embargo, solamente teléfonos registrados y validados por un código SMS podrán recibir 
                   créditos en el celular.
                  </span>
                </li>
                <li>
                  <span>
                  Solamente después de la confirmación del código SMS y validación del saldo de su tarjeta 
                  es que la adición de créditos será hecha en el teléfono pre registrado.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>PAGO DE CUENTA</span>
              <ol>
                <li>
                  <span>
                   Solamente después de la confirmación del código SMS y validación del saldo de su 
                   tarjeta es que el pago será realizado. Un comprobante será generado validando su transacción.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>
               CONSULTA DE SALDO Y EXTRACTO DE LA TARJETA Y ALTERACIÓN DE CONTRASEÑA
              </span>
              <ol>
                <li>
                  <span>
                  En el panel online de la operadora de tarjeta, no tendrá costo.
                  </span>
                </li>
                <li>
                  <span>
                   Para consulta de saldo online o alteración de la contraseña, el premiado 
                   deberá estar con el registro completo en el panel da operadora de la tarjeta.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>BANCO 24h</span>
              <ol>
                <li>
                  <span>
                   El Premiado puede consultar el saldo en los bancos 24h mediante tasa de Gs 2,24.
                  </span>
                </li>
                <li>
                  <span>
                   El Premiado puede retirar dinero en la red Banco 24h mediante tarifa de Gs 9,00.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TASA DE INACTIVIDAD</span>
              <ol>
                <li>
                  <span>
                  Compañero Chamex, deberá enviar una foto de la Nota Fiscal / Cupón Fiscal por el 
                  site www.companerochamex.com para que la puntuación sea realizada y confirmada.
                  </span>
                </li>
                <li>
                  <span>
                  En el caso de que la tarjeta estuviera sin saldo, no será cobrada la tasa de inactividad.
                  </span>
                </li>
                <li>
                  <span>
                  En el caso de perder la tarjeta, usted podrá recibir una nueva tarjeta sin 
                  costo, más para transferir el saldo para una nueva tarjeta, será cobrado 
                  por la operadora de la tarjeta una tasa de 2% de su saldo actual.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>MASTERCARD® SORPRENDA</span>
              <ol>
                <li>
                  <span>
                   El Usuario puede registrarse en el programa MASTERCARD® 
                   SORPRENDA, que es un programa de ventajas administrado por MASTERCARD®.
                  </span>
                </li>
                <li>
                  <span>
                   Todo el acceso y control del programa MASTERCARD® 
                   SORPRENDA es separado e independente de Compañero Chamex .
                  </span>
                </li>
              </ol>
            </li> */}
          </ol>
        </div>
      </div>
    </Container>
  );
}
