/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import 'moment/locale/pt-br';
import { formatMilha } from '../../../utils/format';

const { SearchBar } = Search;

export default function HistoricoVendas() {
  const ExportCSV = (props) => {
    return (
      <div>
        <CSVLink
          data={props.data}
          separator={';'}
          filename={`Medicion${moment().format('YYYYMMDDHHmmss')}.csv`}
          headers={[
            { label: 'Red', key: 'rede' },
            { label: 'Enero', key: 'janeiro' },
            { label: 'Febrero', key: 'fevereiro' },
            { label: 'Marzo', key: 'março' },
            { label: 'Abril', key: 'abril' },
            { label: 'Mayo', key: 'maio' },
            { label: 'Junio', key: 'junho' },
            { label: 'Julio', key: 'julho' },
            { label: 'Agosto', key: 'agosto' },
            { label: 'Setiembre', key: 'setembro' },
            { label: 'Octubre', key: 'outubro' },
            { label: 'Noviembre', key: 'novembro' },
            { label: 'Diciembre', key: 'dezembro' },
            // { label: 'Red', key: 'rede' },
            // { label: 'Mes Pasado', key: mesAnteriorSelecionado ? mesAnteriorSelecionado : mesAnterior },
            // { label: 'Mes Actual', key: mesSelecionado ? mesSelecionado : mesAtual },
            // { label: 'Resultado Mensual', key: `resultadoMensalCsv` },
            // { label: 'Año', key: `ano` },
          ]}
        >
          <button className="btn-primario-slim ml-2">
            <i className="fa fa-file-download px-2"></i>
          </button>
        </CSVLink>
      </div>
    );
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [dataFormated, setDataFormated] = useState([]);
  const [anos, setAnos] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState(null);
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);
  // const [mesSelecionado, setMesSelecionado] = useState(null);
  // const [mesAnteriorSelecionado, setMesAnteriorSelecionado] = useState(null);
  // const [mesAtual, setMesAtual] = useState();
  // const [mesAnterior, setMesAnterior] = useState();
  // const [resultadoMensalCsv, setResultadoMensalCsv] = useState('');

  // const meses = [
  //   { id: 1, label: 'Enero', key: 'janeiro' },
  //   { id: 2, label: 'Febrero', key: 'fevereiro' },
  //   { id: 3, label: 'Marzo', key: 'março' },
  //   { id: 4, label: 'Abril', key: 'abril' },
  //   { id: 5, label: 'Mayo', key: 'maio' },
  //   { id: 6, label: 'Junio', key: 'junho' },
  //   { id: 7, label: 'Julio', key: 'julho' },
  //   { id: 8, label: 'Agosto', key: 'agosto' },
  //   { id: 9, label: 'Setiembre', key: 'setembro' },
  //   { id: 10, label: 'Octubre', key: 'outubro' },
  //   { id: 11, label: 'Noviembre', key: 'novembro' },
  //   { id: 12, label: 'Diciembre', key: 'dezembro' },
  // ]

  const columns = [
    {
      dataField: 'rede',
      text: 'Red',
      sort: true,
    },
    {
      dataField: 'janeiro',
      text: 'Enero',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.janeiro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'fevereiro',
      text: 'Febrero',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.fevereiro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'marco',
      text: 'Marzo',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.marco).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'abril',
      text: 'Abril',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.abril).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'maio',
      text: 'Mayo',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.maio).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'junho',
      text: 'Junio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.junho).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'julho',
      text: 'Julio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.julho).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'agosto',
      text: 'Agosto',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.agosto).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'setembro',
      text: 'Setiembre',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.setembro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'outubro',
      text: 'Octubre',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.outubro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'novembro',
      text: 'Noviembre',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.novembro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'dezembro',
      text: 'Diciembre',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.dezembro).toFixed(0);
      },
      sortFunc: sortFunc,
    },
    // {
    //   dataField: mesAnterior,
    //   text: 'Mes Pasado',
    //   sort: true,
    //   formatter: (celula, valor) => {
    //     // return formatMilha(Number(valor[`${mesAnterior}`]));
    //     if(mesSelecionado) {
    //       if(mesSelecionado === 'janeiro') return '-';
    //       if(isNaN(formatMilha(Number(valor[mesAnteriorSelecionado])))) return 0;
    //       return formatMilha(Number(valor[mesAnteriorSelecionado]));
    //     };
    //     if(mesAtual === 'janeiro') return '-';
    //     return formatMilha(Number(valor[`${mesAnterior}`]));
    //   },
    //   sortFunc: sortFunc,
    // },
    // {
    //   dataField: mesAtual,
    //   text: 'Mes Actual',
    //   sort: true,
    //   formatter: (celula, valor) => {
    //     if(mesSelecionado) {
    //       if(isNaN(formatMilha(Number(valor[`${mesSelecionado}`])))) return 0;
    //       return formatMilha(Number(valor[`${mesSelecionado}`]));
    //     }
    //     return formatMilha(Number(valor[`${mesAtual}`]));
    //   },
    //   sortFunc: sortFunc,
    // },
    // {
    //   dataField: 'resultadoMensal',
    //   text: 'Resultado Mensual',
    //   sort: true,
    //   formatter: (celula, valor) => {
    //     if(mesSelecionado) {
    //       const pegarPorcentagem = (valorAntigo, valorAtual) => {
    //         const diminuirValor = valorAntigo - valorAtual;
    //         return diminuirValor / valorAntigo
    //       }

    //       let resultado = pegarPorcentagem(valor[`${mesAnteriorSelecionado}`], valor[`${mesSelecionado}`]);

    //       if(isNaN(valor[`${mesAnteriorSelecionado}`]) && valor[`${mesSelecionado}`] !== 0) {
    //         setResultadoMensalCsv('+100%');
    //         return '+100%';
    //       };

    //       if(isNaN(valor[`${mesSelecionado}`]) && valor[`${mesAnteriorSelecionado}`] !== 0) {
    //         setResultadoMensalCsv('-100%')
    //         return '-100%'
    //       };

    //       if((valor[`${mesSelecionado}`] === 0 && valor[`${mesAnteriorSelecionado}`] === 0) || isNaN(resultado)) {
    //         setResultadoMensalCsv(0)
    //         return 0;
    //       };

    //       if((valor[`${mesSelecionado}`] === 0 || valor[`${mesAnteriorSelecionado}`] === 0) && resultado !== 0) {
    //         setResultadoMensalCsv('-100%')
    //         return '-100%';
    //       };

    //       if(resultado < 1 && resultado > 0) {
    //         const calculo = '-' + ((Number(resultado) * 100).toFixed(0)) + '%';
    //         setResultadoMensalCsv(calculo);
    //         return calculo;
    //       };
    //       const calculo = (Math.abs(resultado).toFixed(2) * 100) + '%';
    //       setResultadoMensalCsv(calculo);
    //       return calculo;
    //     }

    //     let resultado = (valor[`${mesAtual}`] - valor[`${mesAnterior}`]) / valor[`${mesAnterior}`];

    //     if((valor[`${mesAtual}`] === 0 && valor[`${mesAnterior}`] === 0) || isNaN(resultado)) {
    //       return 0;
    //     };
    //     if((valor[`${mesAtual}`] !== 0 || valor[`${mesAnterior}`] !== 0) && resultado === 0) {
    //       return '-100%';
    //     };
    //     if(resultado < 1) {
    //       return ((Number(resultado) * 100).toFixed(0)) + '%';
    //     };
    //     if(resultado < -1) {
    //       return ((Math.round(resultado) * 100).toFixed(0)) + '%'
    //     }
    //     return Math.round(resultado) + '%';
    //   },
    //   sortFunc: sortFunc,
    // },
  ];

  useEffect(() => {
    buscarRedes();
    buscarPrimeiraData();
    buscarHistorico();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    // const mesActual = moment().format('MMMM');
    // const mesActualNum = Number(moment().format('MM'));
    // const mesPasadoNum = mesActualNum !== 1 ? Number(mesActualNum - 1) : 12;
    // const mesPasado = moment(`00/${mesPasadoNum}/22`).format('MMMM');
    // setMesAtual(mesActual);
    // setMesAnterior(mesPasado);

    // const pegarPorcentagem = (valorAntigo, valorAtual) => {
    //   const diminuirValor = valorAntigo - valorAtual;
    //   const resultado =  diminuirValor / valorAntigo;

    //   if(isNaN(valorAntigo) && valorAtual !== 0) {
    //     return '+100%';
    //   };

    //   if(isNaN(valorAtual) && valorAntigo !== 0) {
    //     return '-100%'
    //   };

    //   if((valorAtual === 0 && valorAntigo === 0) || isNaN(resultado)) {
    //     return 0;
    //   };

    //   if((valorAtual === 0 || valorAntigo === 0) && resultado !== 0) {
    //     return '-100%';
    //   };

    //   if(resultado < 1 && resultado > 0) {
    //     const calculo = '-' + ((Number(resultado) * 100).toFixed(0)) + '%';
    //     return calculo;
    //   };
    //   const calculo = (Math.abs(resultado).toFixed(2) * 100) + '%';
    //   return calculo;
    // }

    if (data) {
      setDataFormated(
        data.map((d) => ({
          rede: d.rede,
          janeiro: Number(d.janeiro).toFixed(0),
          fevereiro: Number(d.fevereiro).toFixed(0),
          março: Number(d.marco).toFixed(0),
          abril: Number(d.abril).toFixed(0),
          maio: Number(d.maio).toFixed(0),
          junho: Number(d.junho).toFixed(0),
          julho: Number(d.julho).toFixed(0),
          agosto: Number(d.agosto).toFixed(0),
          setembro: Number(d.setembro).toFixed(0),
          outubro: Number(d.outubro).toFixed(0),
          novembro: Number(d.novembro).toFixed(0),
          dezembro: Number(d.dezembro).toFixed(0),
          ano: d.ano,
          // resultadoMensal: d,
          // resultadoMensalCsv: mesSelecionado ?
          //   (mesSelecionado === 'janeiro') ?
          //     pegarPorcentagem('-', d[`${mesSelecionado}`])
          //     :
          //     pegarPorcentagem(d[`${mesAnteriorSelecionado}`], d[`${mesSelecionado}`])
          //   :
          //   (mesActual === 'janeiro') ?
          //     pegarPorcentagem('-', d[`${mesActual}`])
          //     :
          //     pegarPorcentagem(d[`${mesPasado}`], d[`${mesActual}`])
        }))
      );
    }
  }, [data]);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarPrimeiraData = async () => {
    const resultado = await api.get('/api/historico/primeiraData');
    if (resultado && resultado.data.primeiraData) {
      const anos = [];
      for (
        let i = moment(resultado.data.primeiraData).year();
        i <= moment().year();
        i += 1
      ) {
        anos.push(i);
      }
      setAnos(anos);
    }
  };

  const buscarHistorico = async () => {
    // if(mesSelecionado) {
    //   let mesAnteriorSelecionado = meses.map((mes, index) => {
    //     if(mesSelecionado === 'janeiro') return 'dezembro';
    //     if(mes.key === mesSelecionado) return meses[index - 1].key;
    //   })
    //   let mesAnteriorSelecionad = mesAnteriorSelecionado !== 'dezembro' ? mesAnteriorSelecionado.filter(mes => mes !== undefined) : 'dezembro';

    //   if(mesAnteriorSelecionad === 12) {
    //     setMesAnteriorSelecionado(mesAnteriorSelecionad)
    //   } else {
    //     setMesAnteriorSelecionado(mesAnteriorSelecionad[0])
    //   }
    // }

    setBuscando(true);
    let ano = anoSelecionado;

    const resultado = await api.get(
      `/api/produtos_vendidos/vendastotais/${redeSelecionada}/${ano}`
    );
    if (resultado) {
      setData(resultado.data.historico);
    }
    setBuscando(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Sin resultados
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Histórico</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Seleccione una red</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="null">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        {/* <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mes</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            value={mesSelecionado}
          >
            <option value="">
              Mes
            </option>
            {meses.map((mes, index) => (
              <option value={mes.key} key={index}>
                {mes.label}
              </option>
            ))}
          </select>
        </Col> */}
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Año</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            value={anoSelecionado}
          >
            <option value="" disabled>
              Selecione um año
            </option>
            {anos.map((ano) => (
              <option value={ano} key={ano}>
                {ano}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarHistorico}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="rede"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
