import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarUsuario } from '../../../store/modules/autenticacao/actions';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector(
    (state) => state.autenticacao.autenticandoPainel
  );

  useEffect(() => {
    document.title = 'Compañero Chamex - Login';
  }, []);

  const solicitarAutenticacao = ({ rut, senha }) => {
    dispatch(autenticarUsuario(rut, senha));
  };

  return (
    <div className="d-flex flex-column justify-content-center bg-black bg-full">
      <Container fluid>
        <Row className="flex-column align-items-center justify-content-center mt-5">
          <img
            className="w-100 form-login-logo"
            src="/imagens/novologochamex.png"
            alt="Logo Compañero Chamex"
          ></img>
        </Row>
        <Row className="justify-content-center p-5 bg-black">
          <Form className="form-login" onSubmit={solicitarAutenticacao}>
            <h5>INICIAR SESIÓN</h5>
            <label>Introduce tus datos y accede a tu cuenta</label>
            <label htmlFor="rut">RUC</label>
            <Input name="rut" type="text" placeholder="RUC"></Input>
            <label htmlFor="senha">Clave</label>
            <Input name="senha" type="password" placeholder="Clave"></Input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
          </Form>
        </Row>
      </Container>
    </div>
  );
}
