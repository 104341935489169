/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';

const { SearchBar } = Search;

export default function Logs() {
  const [buscando, setBuscando] = useState(false);
  const [tiposLog, setTiposLog] = useState([]);
  const [tipoLogSelecionado, setTipoLogSelecionado] = useState('');
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    buscarTiposLog();
    buscarLogs();
  }, []);

  const buscarTiposLog = async () => {
    const resultado = await api.get('/api/logs/tipos');
    if (resultado) {
      setTiposLog(resultado.data.tipos);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const columns = [
    { dataField: 'id', text: 'ID', sort: false, hidden: true },
    {
      text: 'Responsável',
      dataField: 'responsavel',
      sort: true,
    },
    {
      text: 'Tipo',
      dataField: 'tipo',
      sort: true,
    },
    {
      text: 'Parâmetros',
      dataField: 'parametros',
      sort: true,
    },
    {
      text: 'Data',
      dataField: 'criadoEm',
      sort: true,
      sortFunc: sortFuncDate,
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarLogs = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/logs?tipo=${tipoLogSelecionado}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
    );
    if (resultado) {
      setData(resultado.data.logs);
    }
    setBuscando(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Ningún resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Logs</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="tipoLogSelecionado">Selecione o tipo de log</label>
          <select
            className="input-select mb-3"
            name="tipoLogSelecionado"
            id="tipoLogSelecionado"
            onChange={(e) => setTipoLogSelecionado(e.target.value)}
            value={tipoLogSelecionado}
          >
            <option value="">Todos</option>
            {tiposLog.map((t) => (
              <option value={t.tipo} key={t}>
                {t.titulo}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Hasta</label>
          <input
            type="date"
            className="input-theme mb-3"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarLogs}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
