import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Header from '../../../components/Loja/Header';
import Footer from '../../../components/Loja/Footer';

export default function Regulamento() {
  return (
    <>
      <Header auto />

      <Container className="mt-5 mb-5">
        <Row className="flex-column align-items-center justify-content-center mt-5">
          <img
            className="w-100 form-logo"
            src="/imagens/logo-2.png"
            alt="Logo Compañero Chamex"
          ></img>
        </Row>
        <div class="text-justify">
          <div class="mt-5 mb-5">
            <h4 class="f-black text-center font-weight-bold mb-4">
              POLÍTICA DE PRIVACIDAD
            </h4>
            <p>
              Por favor, lea esta Política de Privacidad (“Política”)
              cuidadosamente para entender nuestras políticas y prácticas sobre
              sus Datos Personales (“Datos”) y como los utilizaremos.
            </p>
            <p>
              Esta Política se aplica a individuos que se registraron en
              Compañero Chamex (“Usted”).
            </p>
            <p>
              Esta Política explica como sus Datos Personales son colectados,
              usados y divulgados por Compañero Chamex (“Programa”).{' '}
            </p>
            <p>
              La Política también informa como usted puede entrar y actualizar
              sus Datos Personales e tomar decisiones sobre como sus Datos
              Personales son utilizados.
            </p>
            <p>
              El participante, cuando se registra en el programa, acepta y está
              de acuerdo con los términos descritos abajo:
            </p>
          </div>
          <br />
          <div>
            <ol>
              <li class="font-weight-bold f-18 mb-3">
                <span>Como colectamos informaciones personales.</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Cuando usted se registra en Compañero Chamex Mas Ventajas,
                    nosotros de “Smart Tecnología em Vendas S/A”, empresa que
                    desarrolla y que ejecuta el Programa, colectamos Datos sobre
                    usted para la creación de un Nombre de usuario y contraseña
                    (“Acceso a Compañero Chamex Mas Ventajas”).
                  </p>
                  <p class="font-weight-normal f-16">
                    Durante el proceso de registro, nosotros solicitamos Datos
                    como su nombre completo, dirección de e-mail, fecha de
                    nacimiento, género, CPF, dirección, teléfono fijo y/o
                    celular.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Uso de las informaciones personales</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Los datos colectados serán utilizados con la finalidad de
                    administración y operación del Programa, incluyendo el uso
                    para los fines siguientes:
                  </p>
                  <ul class="font-weight-normal f-16 ml-5">
                    <li>
                      Registro junto al Programa y contacto con los miembros
                      registrados;
                    </li>
                    <li>Acumulación de PONTOS;</li>
                    <li>Entrega de premiaciones;</li>
                    <li>Optimización del catálogo de premios;</li>
                    <li>
                      Desarrollo de inteligencia de mercado, parámetros y perfil
                      de consumo, así como otras actividades asociadas al buen
                      funcionamiento y desarrollo del PROGRAMA;
                    </li>
                    <li>
                      o Reporte, estudios o indicadores de mercado y su
                      respectiva disponibilidad única y exclusivamente para
                      COMPAÑERO CHAMEX SOUTH AMERICA.
                    </li>
                  </ul>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Compartir informaciones personales</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    El Programa no divulga, bajo ninguna hipótesis, las
                    informaciones personales para terceros, excepto para
                    COMPAÑERO CHAMEX SOUTH AMERICA.
                  </p>
                  <p class="font-weight-normal f-16">
                    Conforme previsto en el Reglamento del Programa, COMPAÑERO
                    CHAMEX SOUTH AMERICA garantiza que los datos recibidos serán
                    utilizados para análisis de mercado y reenvío de publicidad
                    sobre productos de marca Compañero Chamex.{' '}
                  </p>
                  <p class="font-weight-normal f-16">
                    Compañero Chamex garantiza que los datos recibidos serán
                    almacenados en un ambiente seguro y con acceso restringido
                    solamente a empleados de la empresa, todos obligados por
                    contrato a mantenerlos en seguridad y confidencialidad.
                    Compañero Chamex garantiza también que los datos serán
                    compartidos con empresas proveedoras de servicio de
                    almacenamiento y gerenciamiento de datos, todos obligados
                    por contrato a mantenerlos en seguridad y confidencialidad.
                    Cuando sea muy necesario, Compañero Chamex podrá compartir
                    los datos recibidos con proveedores de servicio, para
                    mejorar las actividades de la empresa, así como con asesores
                    jurídicos, para entrar o defenderse de acciones
                    administrativas o judiciales. Todos por contrato son
                    obligados a mantener a confidencialidad de las
                    informaciones. Se podrá también compartir los datos para el
                    cumplimiento de ordenes de autoridad judicial o
                    administrativa competente.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Preservación de informaciones personales</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    No mantenemos sus informaciones personales por más tiempo de
                    lo necesario para los motivos para los cuales fueron
                    procesadas. Si existieran limitaciones técnicas que
                    impidieran la exclusión o anonimato, protegeremos las
                    informaciones personales y limitaremos el uso activo de esas
                    informaciones.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Sus Opciones</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Datos Personales: Será posible entrar y revisar estas
                    informaciones personales utilizadas a la hora de registro a
                    cualquier momento en el menú “Mi Cuenta” Después de
                    registrar el nombre de usuario y la contraseña.
                  </p>
                  <p class="font-weight-normal f-16">
                    Exclusión de Datos Personales: Se desea cancelar su
                    participación en el programa, usted puede enviar un e-mail
                    para{' '}
                    <a href="mailto:contacto@companerochamex.com ">
                      contacto@companerochamex.com{' '}
                    </a>{' '}
                    realizando la solicitud.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Donde almacenamos sus datos</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Los datos son almacenados en bancos de datos exclusivos del
                    Programa, en ambiente totalmente seguro.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Alteraciones de esta Política</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Si hay alteraciones en la forma en que utilizaremos sus
                    Datos Personales, vamos actualizar esta Política. Nos
                    reservamos el derecho de hacer alteraciones a nuestras
                    prácticas y a esta Política en cualquier momento.
                  </p>
                </li>
              </li>
              <li class="font-weight-bold f-18 mb-3">
                <span>Contacto</span>
                <li class="mt-2">
                  <p class="font-weight-normal f-16">
                    Para dudas o reclamos sobre nuestro uso de sus informaciones
                    personales o sobre nuestra Política de privacidad, entre em
                    contacto con nosotros por e-mail
                    <a href="mailto:contacto@companerochamex.com">
                      contacto@companerochamex.com
                    </a>
                  </p>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
