import React, { useState, useEffect } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import validarCpf from '../../../../utils/validarCpf';
import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
import axios from 'axios';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endCidade, setCidade] = useState('');
  const [hobby, setHobby] = useState('');

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Sexo obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const validarBuscarCpf = (e) => {
    const cpf = e.target.value;
    if (!cpf) return;
    if (!validarCpf(cpf)) {
      toast.error('RUT no es válido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    }
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  const atualizar = async (e) => {
    e.preventDefault();
    const dataUpdated = {
      nome,
      cpf,
      telefone,
      celular,
      email,
      dataNascimento,
      sexo,
      endRua,
      endNumero,
      endComplemento,
      endCidade,
      hobby,
    };

    const temNulo = validarCampos(dataUpdated);
    if (temNulo) {
      return;
    }

    dataUpdated.telefone = dataUpdated.telefone.replace(/[^\d]/g, '');
    dataUpdated.celular = dataUpdated.celular.replace(/[^\d]/g, '');
    dataUpdated.cpf = dataUpdated.cpf;
    dataUpdated.dataNascimento = dataUpdated.dataNascimento.replace(
      /[^\d]/g,
      ''
    );
    dataUpdated.dataNascimento = `${dataUpdated.dataNascimento.substring(
      4
    )}${dataUpdated.dataNascimento.substring(
      2,
      4
    )}${dataUpdated.dataNascimento.substring(0, 2)}`;

    setEditando(true);
    const resultado = await api.put(`/api/parceiros/${data.id}`, dataUpdated);

    if (resultado) {
      toast.success(`Parceiro alterado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarParceiro({ ...dataUpdated, id: data.id });
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setNome(props.data.nome);
      setCpf(props.data.cpf);
      setTelefone(props.data.telefone);
      setCelular(props.data.celular);
      setEmail(props.data.email);
      setDataNascimento(moment(props.data.dataNascimento).format('DD/MM/YYYY'));
      setSexo(props.data.sexo);
      setRua(props.data.endRua);
      setNumero(props.data.endNumero);
      setComplemento(props.data.endComplemento);
      setCidade(props.data.endCidade);
      setHobby(props.data.hobby);
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <form className="form" onSubmit={atualizar}>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="cpf">RUT</label>
                <InputMask
                  id="cpf"
                  name="cpf"
                  type="text"
                  placeholder="RUT"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  onBlur={validarBuscarCpf}
                ></InputMask>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="dataNascimento">Data de Nascimento</label>
                <InputMask
                  id="dataNascimento"
                  name="dataNascimento"
                  type="text"
                  placeholder="Data de Nascimento"
                  value={dataNascimento}
                  onBlur={validarDataNascimento}
                  onChange={(e) => setDataNascimento(e.target.value)}
                  mask="99/99/9999"
                ></InputMask>
              </Col>
            </Row>

            <label htmlFor="nome">Nome</label>
            <input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></input>
            <label htmlFor="nome">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="celular">Celular</label>
                <InputMask
                  id="celular"
                  name="celular"
                  type="text"
                  placeholder="Celular"
                  value={celular}
                  mask="(999) 999-999999"
                  onChange={(e) => setCelular(e.target.value)}
                ></InputMask>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="telefone">Telefone</label>
                <InputMask
                  id="telefone"
                  name="telefone"
                  type="text"
                  placeholder="Telefone"
                  value={telefone}
                  mask="(999) 999-999999"
                  onChange={(e) => setTelefone(e.target.value)}
                ></InputMask>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={12}>
                <label htmlFor="sexo">Sexo</label>
                <select
                  id="sexo"
                  name="sexo"
                  value={sexo}
                  onChange={(e) => setSexo(e.target.value)}
                  placeholder="Sexo"
                >
                  <option disabled value="">
                    Selecione o sexo
                  </option>
                  <option value="F">Feminino</option>
                  <option value="M">Masculino</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={9}>
                <label htmlFor="endRua">Endereço</label>
                <input
                  id="endRua"
                  name="endRua"
                  type="text"
                  placeholder="Endereço"
                  value={endRua}
                  onChange={(e) => setRua(e.target.value)}
                ></input>
              </Col>

              <Col className="d-flex flex-column" xs={12} sm={3}>
                <label htmlFor="endNumero">Número</label>
                <input
                  id="endNumero"
                  name="endNumero"
                  type="text"
                  placeholder="Número"
                  value={endNumero}
                  onChange={(e) => setNumero(e.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endComplemento">Complemento</label>
                <input
                  id="endComplemento"
                  name="endComplemento"
                  type="text"
                  placeholder="Complemento"
                  value={endComplemento}
                  onChange={(e) => setComplemento(e.target.value)}
                ></input>
              </Col>

              <Col className="d-flex flex-column" xs={12} sm={6}>
                <label htmlFor="endCidade">Cidade</label>
                <input
                  id="endCidade"
                  name="endCidade"
                  type="text"
                  placeholder="Cidade"
                  value={endCidade}
                  onChange={(e) => setCidade(e.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column" xs={12}>
                <label htmlFor="hobby">Hobby Favorito</label>
                <input
                  id="hobby"
                  name="hobby"
                  type="text"
                  placeholder="Hobbie"
                  value={hobby}
                  onChange={(e) => setHobby(e.target.value)}
                ></input>
              </Col>
            </Row>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
