import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalAlterarStatusResgate(props) {
  const [alterando, setAlterando] = useState(false);
  const [status, setStatus] = useState('');

  const alterarStatusResgate = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/alterarStatusResgate', {
      identificador: props.resgate,
      status,
    });

    if (resultado) {
      toast.success(`Status atualizado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      historico.push('/painel/resgates');
    }
    setAlterando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Cambiar Estado Resgate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form className="form mw-100 mt-4 d-flex justify-content-center align-items-center">
          <select
            id="status"
            name="status"
            placeholder="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option disabled value="">
              Cambiar
            </option>
            <option value="0">No</option>
            <option value="1">Sí</option>
            <option value="2">En camino</option>
          </select>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarStatusResgate}
              disabled
            >
              CAMBIANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarStatusResgate}
            >
              CAMBIAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
