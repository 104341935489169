import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import InputMask from 'react-input-mask';

export default function CadastrarAdministrador() {
  const [enviando, setEnviando] = useState(false);
  const [rut, setRut] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const cadastrarAdministrador = async (e) => {
    e.preventDefault();
    if (!nome) {
      toast.error('Nombre obligatorio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!rut) {
      toast.error('RUC obligatorio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!email) {
      toast.error('Email obligatorio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!senha) {
      toast.error('Clave obligatorio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/usuarios', {
      nome,
      email,
      rut,
      senha,
    });

    if (resultado) {
      toast.success('Administrador registrado correctamente', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    }
    setEnviando(false);
  };

  const resetForm = () => {
    setRut('');
    setNome('');
    setEmail('');
    setSenha('');
  };

  const buscarRut = async (e) => {
    const newRut = e.target.value;

    if (!newRut) return;

    const resultado = await api.get(`/api/usuarios?rut=${newRut}`);

    if (resultado && resultado.data.usuarios.length > 0) {
      toast.error('Usuario ya registrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setRut('');
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Registrar Administrador</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarAdministrador}>
          <label htmlFor="nome">Nombre</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            onChange={(e) => setNome(e.target.value)}
            value={nome}
          ></input>
          <label htmlFor="rut">RUC</label>
          <InputMask
            id="rut"
            name="rut"
            type="text"
            placeholder="Rut"
            onChange={(e) => setRut(e.target.value)}
            onBlur={buscarRut}
            value={rut}
          />
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <label htmlFor="senha">Senha</label>
          <input
            id="senha"
            name="senha"
            type="password"
            placeholder="Senha"
            onChange={(e) => setSenha(e.target.value)}
            value={senha}
          ></input>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
