/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { toast } from 'react-toastify';
import Toggle from 'react-bootstrap-toggle';
import { useSelector } from 'react-redux';
import { formatCelular } from '../../../utils/format';
import { CSVLink } from 'react-csv';


const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'nomeFantasia',
    order: 'asc',
  },
];

export default function Revendas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const [dataFormated, setDataFormated] = useState([]);

  const [data, setData] = useState([]);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [revenda, setRevenda] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);

  const columns = [
    {
      dataField: 'codigo',
      text: 'Código',
      sort: true,
    },
    {
      dataField: 'nomeFantasia',
      text: 'Nombre Comercial',
      sort: true,
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) => {
              e.preventDefault();
              historico.push(`revenda/${valor.cnpj}`);
            }}
          >
            <b>{valor.nomeFantasia}</b>
          </button>
        );
      },
    },
    {
      dataField: 'razaoSocial',
      text: 'Razón Social',
      sort: true,
    },
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      formatter: (celula, valor) => {
        let cnpj = valor.cnpj.replace(/[^\d]/g, '');
        return cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      },
      sort: true,
    },
    {
      dataField: 'telefone',
      text: 'Contacto',
      sort: true,
      formatter: (celula, valor) => {
        return formatCelular(valor.telefone);
      },
    },
    {
      dataField: 'endCidade',
      text: 'Ciudad',
      sort: true,
    },
    {
      dataField: 'criadoEm',
      text: 'Fecha de Registro ',
      formatter: (celula, valor) => {
        return valor.criadoEm && valor.criadoEm !== 0
          ? moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss')
          : '';
      },
      sort: true,
    },
    {
      dataField: 'numeroParceiros',
      text: 'Número Vendedores Mayoristas',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalVendas',
      text: 'Total Ventas',
      formatter: (celula, valor) => {
        return Number(valor.totalVendas)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalCusto',
      text: 'Total Costo',
      formatter: (celula, valor) => {
        return Number(valor.totalCusto)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPontos',
      text: 'Puntuación Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Puntos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'ultimaPontuacao',
      text: 'Última Puntuación',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Activo',
      sort: true,
      hidden: usuario.permissao > 1 ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>SI</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: usuario.permissao > 0 ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Borrar',
      sort: false,
      hidden: usuario.permissao > 1 ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nomeFantasia);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarRevendas();
  }, []);

  const buscarRevendas = async () => {
    let params = {
      rede: redeSelecionada,
    };
    if (usuario.permissao < 2) {
      params.ativo = 1;
    }
    const searchParams = new URLSearchParams(params);

    setBuscando(true);
    const resultado = await api.get(`/api/revendas?${searchParams.toString()}`);
    if (resultado) {
      setData(resultado.data.revendas);
    }
    setBuscando(false);
  };

  const ExportCSV = (props) => {
    return (
      <div>
        <CSVLink
          data={props.data}
          separator={';'}
          filename={`Mayoristas${moment().format('YYYYMMDDHHmmss')}.csv`}
          headers={[
            { label: 'Código', key: 'codigo'},
            { label: 'Nombre Comercial', key: 'nomeFantasia'},
            { label: 'Razón Social', key: 'razaoSocial'},
            { label: 'CNPJ', key: 'cnpj'},
            { label: 'Contacto', key: 'telefone'},
            { label: 'Ciudad', key: 'endCidade'},
            { label: 'Fecha de Registro ', key: 'criadoEm'},
            { label: 'Número Vendedores Mayoristas', key: 'numeroParceiros'},
            { label: 'Total Ventas', key: 'totalVendas'},
            { label: 'Total Costo', key: 'totalCusto'},
            { label: 'Puntuación Total', key: 'totalPontos'},
            { label: 'Saldo de Puntos', key: 'saldoPontos'},
            { label: 'Última Puntuación', key: 'ultimaPontuacao'},
            { label: 'Activo', key: 'ativo'},
          ]}
        >
          <button className="btn-primario-slim ml-2">
            <i className="fa fa-file-download px-2"></i>
          </button>
        </CSVLink>
      </div>
    );
  };

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          codigo: d.codigo,
          nomeFantasia: d.nomeFantasia,
          razaoSocial: d.razaoSocial,
          cnpj: d.cnpj,
          telefone: d.telefone,
          endCidade: d.endCidade,
          criadoEm: moment(d.criadoEm).format('DD/MM/YYYY HH:mm:ss'),
          numeroParceiros: d.numeroParceiros,
          totalVendas: Number(d.totalVendas)
          .toLocaleString('es-CL', {
            style: 'currency',
            currency: 'CLP',
          })
          .toString()
          .replace(/\u00a0/g, ' '),
          totalCusto: Number(d.totalCusto)
          .toLocaleString('es-CL', {
            style: 'currency',
            currency: 'CLP',
          }),
          totalPontos: d.totalPontos,
          saldoPontos: d.saldoPontos,
          ultimaPontuacao: moment(d.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss'),
          ativo: d.ativo ? 'SIM' : 'NÃO',
        }))
      );
    }
  }, [data]);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Ningún resultado
        </span>
      ),
  };

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/revendas/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Revenda atualizada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setData(
      data.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, revenda) => {
    setRevenda(revenda);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setRevenda('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarRevenda = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Mayoristas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Seleccione una red</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarRevendas}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sin resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                {...props.baseProps}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        revenda={revenda}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarRevenda={atualizarRevenda}
      />
    </Container>
  );
}
