import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';

const { SearchBar } = Search;

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`Relatorio${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          { label: 'Red', key: 'rede' },
          { label: 'Período', key: 'periodo' },
          { label: 'Pts. Utilizados', key: 'pontosUtilizados' },
          { label: 'Valor Punto', key: 'valorPonto' },
          { label: 'Gs a pagar de pts', key: 'aPagar' },
          { label: 'Tasa de Éxito', key: 'taxaSucesso' },
          { label: 'Valor Total', key: 'valorTotal' },
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

export default function Relatorio() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'rede',
      text: 'Red',
      sort: true,
      footer: (columnData) => null,
    },
    {
      dataField: 'periodo',
      text: 'Período',
      sort: true,
      formatter: (celula, valor) => {
        return `${valor.mes}/${valor.ano}`;
      },
      footer: (columnData) => null,
    },
    {
      dataField: 'pontosUtilizados',
      text: 'Pts. Utilizados',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.pontosUtilizados).toLocaleString('pt-BR');
      },
      footer: (columnData) => null,
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorPonto',
      text: 'Valor Punto',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorPonto)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'aPagar',
      text: 'Gs a pagar de pts',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.aPagar)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
      footer: (columnData) => null,
    },
    {
      dataField: 'taxaSucesso',
      text: 'Tasa de Éxito',
      sort: true,
      formatter: (celula, valor) => {
        return `${Number(valor.taxaSucesso)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ')}`;
      },
      sortFunc: sortFunc,
      footer: (columnData) => 'Total',
    },
    {
      dataField: 'valorTotal',
      text: 'Valor Total',
      sort: true,
      formatter: (celula, valor) => {
        return `${Number(valor.valorTotal)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' ')}`;
      },
      sortFunc: sortFunc,
      footer: (columnData) =>
        data
          .reduce((acc, item) => Number(acc) + Number(item.valorTotal), 0)
          .toLocaleString('es-PY', {
            style: 'currency',
            currency: 'PYG',
          })
          .toString()
          .replace(/\u00a0/g, ' '),
    },
  ];

  const meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  useEffect(() => {
    buscarRedes();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarRelatorio = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/relatorio?rede=${redeSelecionada}&ano=${anoSelecionado}&mes=${(
        Number(mesSelecionado) + 1
      )
        .toString()
        .padStart(2, '0')}`
    );
    if (resultado) {
      setData(resultado.data.relatorios);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [mesSelecionado, setMesSelecionado] = useState(moment().month() - 1);
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          rede: d.rede,
          periodo: `${d.ano}${d.mes}`,
          pontosUtilizados: Number(d.pontosUtilizados).toLocaleString('pt-BR'),
          valorPonto: Number(d.valorPonto)
            .toLocaleString('es-PY', {
              style: 'currency',
              currency: 'PYG',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
          aPagar: Number(d.aPagar)
            .toLocaleString('es-PY', {
              style: 'currency',
              currency: 'PYG',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
          taxaSucesso: Number(d.taxaSucesso)
            .toLocaleString('es-PY', {
              style: 'currency',
              currency: 'PYG',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
          valorTotal: Number(d.valorTotal)
            .toLocaleString('es-PY', {
              style: 'currency',
              currency: 'PYG',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
        }))
      );
    }
  }, [data]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Ningún resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Reporte</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <iframe
        title="Chamex"
        width="100%"
        height="625"
        src="https://app.powerbi.com/reportEmbed?reportId=29a43f8f-27a8-4fbc-8747-0803dadabc97&autoAuth=true&ctid=7b19ce6b-5f49-41c9-bbae-e1c12fcd0b0a" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </Container>
  );
}
