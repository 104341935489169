/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';

export default function PremiosLoja(props) {
  const [premios, setPremios] = useState([]);

  const buscarPremios = async () => {
    const resultado = await api.get(
      `/api/premios?ativo=1${props.tipo ? `&tipo=${props.tipo}` : ''}`
    );
    if (resultado) {
      setPremios(resultado.data.premios.filter((p) => Number(p.pontos) > 0));
    }
  };

  useEffect(() => {
    document.title = 'Compañero Chamex';
    buscarPremios();
  }, []);

  return (
    <Container className="mb-5">
      <div className="mt-3">
        <h5>PREMIOS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <Row>
        {premios.map((premio) => {
          return (
            <Col
              key={premio.codigo}
              id={premio.codigo}
              name="premio"
              xs={12}
              md={4}
              lg={3}
              className="mt-5"
            >
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <Link to={`/premio/${premio.codigo}`}>
                  <img
                    alt={premio.nome}
                    className="w-100"
                    src={premio.diretorioFoto}
                  ></img>
                </Link>

                <span className="card-premio-nome">{premio.nome}</span>
                <span>
                  <b>{Number(premio.pontos).toLocaleString('pt-BR')} puntos</b>
                </span>
                <Link to={`/premio/${premio.codigo}`}>
                  <button className="btn-resgatar mt-1">CANJEAR</button>
                </Link>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
