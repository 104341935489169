import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import './styles.css';
import SelectRevenda from '../SelectRevenda/index';
import historico from '../../../services/Historico';

export default function CadastrarParceiro() {
  const [enviando, setEnviando] = useState(false);
  const [cnpjRevenda, setCnpjRevenda] = useState('');
  // const [qtdeProdutosVendidos, setQtdeProdutosVendidos] = useState('');
  const [qtdeProdutosVendidos] = useState('');
  const [categoria, setCategoria] = useState('');
  const [comunicacao, setComunicacao] = useState('');
  const [qtdeVendedores, setQtdeVendedores] = useState('');
  // const [tamanhoLoja, setTamanhoLoja] = useState('');
  const [faturamento, setFaturamento] = useState('');
  const [produtosMaisVendidos, setProdutosMaisVendidos] = useState([]);
  const [
    produtosMaisVendidosConcorrentes,
    setProdutosMaisVendidosConcorrentes,
  ] = useState([]);
  const [marca, setMarca] = useState('');
  const [produto, setProduto] = useState('');
  const [qtde, setQtde] = useState('');
  const [valorUnitario, setValorUnitario] = useState('');
  const [marcaConcorrente, setMarcaConcorrente] = useState('Compañero Chamex');
  const [produtoConcorrente, setProdutoConcorrente] = useState('');
  const [qtdeConcorrente, setQtdeConcorrente] = useState('');
  const [valorUnitarioConcorrente, setValorUnitarioConcorrente] = useState('');
  const [fonte, setFonte] = useState('');

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[0] === 'qtdeProdutosVendidos') {
        return;
      }
      if (param[0] === 'produtosMaisVendidos' && param[1].length === 0) {
        toast.error(`Adicione 5 produtos mais vendidos`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      } else if (
        param[0] === 'produtosMaisVendidosConcorrentes' &&
        !param[1].length === 0
      ) {
        toast.error(`Adicione 5 produtos concorrentes mais vendidos`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      } else if (!param[1]) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const cadastrarPesquisa = async (e) => {
    e.preventDefault();
    const data = {
      categoria,
      comunicacao,
      cnpjRevenda,
      qtdeProdutosVendidos,
      qtdeVendedores,
      faturamento,
      produtosMaisVendidos: JSON.stringify(produtosMaisVendidos),
      produtosMaisVendidosConcorrentes: JSON.stringify(
        produtosMaisVendidosConcorrentes
      ),
      fonte,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    let quantidadeVendida = 0;
    produtosMaisVendidosConcorrentes.forEach((produto) => {
      quantidadeVendida += Number(produto.qtde);
    });

    // Checagem de produtos vendidos, foi ocultado o campo e atribuido a soma aquantidade de todas os itens informados na pesquisa
    data.qtdeProdutosVendidos = quantidadeVendida;
    // if (quantidadeVendida > qtdeProdutosVendidos) {
    //   toast.error(
    //     'Soma da quantidade dos produtos mais vendidos não pode ser maior que a quantidade total vendida!',
    //     {
    //       position: toast.POSITION.BOTTOM_CENTER,
    //       autoClose: 10000,
    //     }
    //   );
    //   return;
    // }

    setEnviando(true);
    const resultado = await api.post('/api/pesquisa_mercado', data);

    if (resultado) {
      toast.success('Pesquisa de Mercado cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/painel/cadastrar/pesquisa-mercado');
    }
    setEnviando(false);
  };

  const addProdutoConcorrente = (e) => {
    e.preventDefault();
    if (
      !produtoConcorrente ||
      !qtdeConcorrente ||
      !valorUnitarioConcorrente ||
      !marcaConcorrente
    ) {
      toast.error(
        'Informe marca, produto, quantidade e valor unitário para adicionar',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      return;
    }
    const achou = produtosMaisVendidosConcorrentes.find(
      (e) => e.produto === produtoConcorrente && e.marca === marcaConcorrente
    );
    if (achou) {
      toast.error('Produto da mesma marca já adicionado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } else {
      setProdutosMaisVendidosConcorrentes([
        ...produtosMaisVendidosConcorrentes,
        {
          marca: marcaConcorrente,
          produto: produtoConcorrente,
          qtde: qtdeConcorrente,
          valorUnitario: valorUnitarioConcorrente,
        },
      ]);
    }
    setMarcaConcorrente('');
    setProdutoConcorrente('');
    setQtdeConcorrente('');
    setValorUnitarioConcorrente('');
  };

  const addProduto = (e) => {
    e.preventDefault();
    if (!marca || !produto || !qtde || !valorUnitario) {
      toast.error(
        'Informe produto, quantidade e valor unitário para adicionar',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      return;
    }
    const achou = produtosMaisVendidos.find((e) => {
      return e.produto === produto && e.marca === marca;
    });
    if (achou) {
      toast.error('Produto da mesma marca já adicionado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } else {
      setProdutosMaisVendidos([
        ...produtosMaisVendidos,
        { marca, produto, qtde, valorUnitario },
      ]);
    }
    setMarca('');
    setProduto('');
    setQtde('');
    setValorUnitario('');
  };

  const delProduto = (e) => {
    e.preventDefault();
    setProdutosMaisVendidos(
      produtosMaisVendidos.filter(
        (selecionado) => selecionado.produto !== e.target.id
      )
    );
  };

  const delProdutoConcorrente = (e) => {
    e.preventDefault();
    setProdutosMaisVendidosConcorrentes(
      produtosMaisVendidosConcorrentes.filter(
        (selecionado) => selecionado.produto !== e.target.id
      )
    );
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Pesquisa de Mercado</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarPesquisa}>
          <label htmlFor="cnpjRevenda">Mayorista</label>
          <SelectRevenda onChange={(e) => setCnpjRevenda(e.value)} />
          <label htmlFor="faturamento">Faturamento médio Gs</label>
          <input
            id="faturamento"
            name="faturamento"
            type="number"
            step="0.01"
            placeholder="Faturamento médio"
            value={faturamento}
            onChange={(e) => setFaturamento(e.target.value)}
          ></input>
          {/* <label htmlFor="qtdeProdutosVendidos">
            Quantidade de produtos vendidos
          </label>
          <input
            id="qtdeProdutosVendidos"
            name="qtdeProdutosVendidos"
            type="number"
step="0.01"
            placeholder="Quantidade de produtos vendidos"
            value={qtdeProdutosVendidos}
            onChange={(e) => setQtdeProdutosVendidos(e.target.value)}
          ></input> */}
          <label htmlFor="categoria">
            Qual categoria / especialidade de produto é mais vendido?
          </label>
          <input
            id="categoria"
            name="categoria"
            type="text"
            step="0.01"
            placeholder="Qual categoria / especialidade de produto é mais vendido?"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          ></input>
          <label htmlFor="comunicacao">
            Canais de comunicação / marketing que a Mayorista possui
          </label>
          <input
            id="comunicacao"
            name="comunicacao"
            type="text"
            step="0.01"
            placeholder="Canais de comunicação / marketing que a Mayorista possui"
            value={comunicacao}
            onChange={(e) => setComunicacao(e.target.value)}
          ></input>
          <label htmlFor="qtdeVendedores">Quantidade de vendedores</label>
          <input
            id="qtdeVendedores"
            name="qtdeVendedores"
            type="number"
            step="0.01"
            placeholder="Quantidade de vendedores"
            value={qtdeVendedores}
            onChange={(e) => setQtdeVendedores(e.target.value)}
          ></input>
          {/* <label htmlFor="tamanhoLoja">Tamanho da loja (m²)</label>
          <input
            id="tamanhoLoja"
            name="tamanhoLoja"
            type="number"
            step="0.01"
            placeholder="Tamanho da loja (m²)"
            value={tamanhoLoja}
            onChange={(e) => setTamanhoLoja(e.target.value)}
          ></input> */}
          <label className="mt-3">Produtos mais vendidos na loja</label>
          <Row>
            <Col className="fl-d" xs={12} sm={4}>
              <label htmlFor="marca">Marca</label>
              <input
                id="marca"
                className="w-100"
                placeholder="Marca"
                value={marca}
                onChange={(e) => setMarca(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="produto">Produto</label>
              <input
                id="produto"
                className="w-100"
                placeholder="Produto"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
              ></input>
            </Col>
            <Col className="fl-d" xs={12} sm={4}>
              <label htmlFor="qtde">Qtde</label>
              <input
                id="qtde"
                className="w-100"
                placeholder="Qtde"
                type="number"
                step="0.01"
                value={qtde}
                onChange={(e) => setQtde(e.target.value)}
              ></input>
            </Col>
            <Col className="fl-d" xs={12} sm={5}>
              <label htmlFor="vlUnit">Vl. Unit</label>
              <input
                id="vlUnit"
                className="w-100"
                placeholder="Vl. Unit."
                type="number"
                step="0.01"
                value={valorUnitario}
                onChange={(e) => setValorUnitario(e.target.value)}
              ></input>
            </Col>

            <Col
              className="d-flex justify-content-center text-center align-items-center"
              xs={12}
              sm={3}
            >
              <button onClick={addProduto} className="btn-adicionar">
                <i className="fas fa-plus"></i>
              </button>
            </Col>
            <Col xs={12} sm={12}>
              <ul className="lista" id="produtos">
                {produtosMaisVendidos.map((produto, index) => (
                  <li className="lista-item" key={index}>
                    {`${produto.marca} - ${produto.produto} - ${Number(
                      produto.qtde
                    ).toLocaleString('pt-BR')} - ${Number(
                      produto.valorUnitario
                    ).toLocaleString('es-PY', {
                      style: 'currency',
                      currency: 'PYG',
                    })}`}
                    <button onClick={delProduto} className="btn-limpo ml-3">
                      <i id={produto.produto} className="fas fa-trash"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <label>Produtos mais vendido por marcas</label>
          <Row>
            <Col className="fl-d" xs={12} sm={4}>
              <label htmlFor="marcaConcorrente">Marca</label>
              <input
                id="marcaConcorrente"
                className="w-100"
                placeholder="Marca"
                value={marcaConcorrente}
                onChange={(e) => setMarcaConcorrente(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={8}>
              <label htmlFor="produtoConcorrente">Produto</label>
              <input
                id="produtoConcorrente"
                className="w-100"
                placeholder="Produto"
                value={produtoConcorrente}
                onChange={(e) => setProdutoConcorrente(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={4}>
              <label htmlFor="qtdeConcorrente">Qtde</label>
              <input
                id="qtdeConcorrente"
                className="w-100"
                type="number"
                step="0.01"
                placeholder="Qtde"
                value={qtdeConcorrente}
                onChange={(e) => setQtdeConcorrente(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={5}>
              <label htmlFor="vlUnitConcorrente">Vl. Unit</label>
              <input
                id="vlUnitConcorrente"
                className="w-100"
                type="number"
                step="0.01"
                placeholder="Vl. Unit."
                value={valorUnitarioConcorrente}
                onChange={(e) => setValorUnitarioConcorrente(e.target.value)}
              ></input>
            </Col>

            <Col
              className="d-flex justify-content-center text-center align-items-center"
              xs={12}
              sm={3}
            >
              <button onClick={addProdutoConcorrente} className="btn-adicionar">
                <i className="fas fa-plus"></i>
              </button>
            </Col>
            <Col>
              <ul className="lista" id="produtosConcorrente">
                {produtosMaisVendidosConcorrentes.map((produto, index) => (
                  <li className="lista-item" key={index}>
                    {`${produto.marca} - ${produto.produto} - ${Number(
                      produto.qtde
                    ).toLocaleString('pt-BR')} - ${Number(
                      produto.valorUnitario
                    ).toLocaleString('es-PY', {
                      style: 'currency',
                      currency: 'PYG',
                    })}`}
                    <button
                      onClick={delProdutoConcorrente}
                      className="btn-limpo"
                    >
                      <i id={produto.produto} className="fas fa-trash ml-3"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <div className="d-flex flex-row justify-content-center align-items-center text-left">
            <label className="mb-0">Fonte</label>
            <label className="m-0 p-3 f-16" htmlFor="fonteSistema">
              Sistema
            </label>
            <input
              className="m-0 p-3"
              id="fonteSistema"
              name="fonte"
              type="radio"
              value={'0'}
              onChange={(e) => setFonte('Sistema')}
            ></input>
            <label className="m-0 p-3 f-16" htmlFor="fonteSistema">
              Equipe
            </label>
            <input
              className="m-0 p-3"
              id="fonteEquipe"
              name="fonte"
              type="radio"
              value={1}
              onChange={(e) => setFonte('Equipe')}
            ></input>
          </div>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
