export function desautenticar() {
  return {
    type: '@autenticacao/DESAUTENTICAR',
  };
}

export function autenticarUsuario(rut, senha) {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR',
    payload: { rut, senha },
  };
}

export function desautenticarUsuario() {
  return {
    type: '@autenticacaoUsuario/DESAUTENTICAR',
  };
}

export function autenticacaoSucessoUsuario(tokenPainel, usuario) {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR_SUCESSO',
    payload: { tokenPainel, usuario },
  };
}

export function autenticacaoFalhouUsuario() {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR_FALHOU',
  };
}

export function autenticarParceiro(rut, senha) {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR',
    payload: { rut, senha },
  };
}

export function desautenticarParceiro() {
  return {
    type: '@autenticacaoParceiro/DESAUTENTICAR',
  };
}

export function autenticacaoSucessoParceiro(tokenLoja, parceiro) {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR_SUCESSO',
    payload: { tokenLoja, parceiro },
  };
}

export function autenticacaoFalhouParceiro() {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR_FALHOU',
  };
}

export function atualizarAntifraude(redesAntifraude) {
  return {
    type: '@autenticacaoUsuario/ATUALIZAR_ANTIFRAUDE',
    payload: { redesAntifraude },
  };
}
