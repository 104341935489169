/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function EsqueceuSenha(props) {
  const [enviando, setEnviando] = useState(false);
  const [informacao, setInformacao] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [uuidValidado, setUuidValidado] = useState(false);

  const { uuid } = props.match.params;

  useEffect(() => {
    document.title = 'Compañero Chamex - ¿Olvidaste tu contraseña?';

    if (uuid) {
      validarUuid();
    }
  }, []);

  const validarUuid = async () => {
    const resultado = await api.post(
      '/api/sessoes/parceiro/validar-recuperacao',
      {
        uuid,
      }
    );

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setUuidValidado(resultado.data.valido);
    } else {
      historico.push('/login');
    }
  };

  const recuperarSenha = async () => {
    if (!informacao) {
      toast.error('Informe seu Email ou RUC primeiro', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/sessoes/parceiro/esqueceu-senha', {
      informacao,
    });

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setEnviando(false);
  };

  const atualizarSenha = async () => {
    if (!novaSenha) {
      toast.error('Ingrese su nueva contraseña primero', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/sessoes/parceiro/atualizar-senha', {
      novaSenha,
      uuid,
    });

    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      historico.push('/login');
    }
    setEnviando(false);
  };

  const formEmailRut = (
    <Form onSubmit={recuperarSenha} className="form-login form-login-loja">
      <h5>¿Olvidaste tu contraseña?</h5>
      <label className="text-justify mt-2">
        Ingrese su correo electrónico de registro o RUC. Si la información es
        correcta, recibirá un código para restablecer su contraseña.
      </label>
      <label htmlFor="informacao" className="mt-4">
        Correo Electrónico ou RUC
      </label>
      <Input
        name="informacao"
        type="text"
        placeholder="Correo Electrónico ou RUC"
        value={informacao}
        onChange={(e) => setInformacao(e.target.value)}
      ></Input>
      {enviando ? (
        <button disabled className="btn-primario" type="submit">
          ENVIANDO...
        </button>
      ) : (
        <button className="btn-primario" type="submit">
          RECUPERAR CONTRASEÑA
        </button>
      )}
    </Form>
  );

  const formNovaSenha = (
    <Form onSubmit={atualizarSenha} className="form-login form-login-loja">
      <h5>¿Olvidaste tu contraseña?</h5>
      <label className="text-justify mt-2">
        Introduzca su nueva contraseña
      </label>
      <label htmlFor="novaSenha" className="mt-4">
        Nueva Contraseña{' '}
      </label>
      <Input
        name="novaSenha"
        type="password"
        placeholder="Nueva Contraseña"
        value={novaSenha}
        onChange={(e) => setNovaSenha(e.target.value)}
      ></Input>
      {enviando ? (
        <button disabled className="btn-primario" type="submit">
          CAMBIANDO...
        </button>
      ) : (
        <button className="btn-primario" type="submit">
          CAMBIAR
        </button>
      )}
    </Form>
  );

  const validandoUuid = (
    <Form className="form-login form-login-loja">
      <h5>¿Olvidaste tu contraseña?</h5>
      <span className="mt-3 mb-3 text-center">
        Validando enlace de recuperación, por favor espere...{' '}
      </span>
      <div className="fa-2x text-center">
        <i className="fas fa-circle-notch fa-spin" />
      </div>
    </Form>
  );

  return (
    <>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        <Row className="justify-content-center mt-5">
          <img
            className="w-100 form-logo"
            src="/imagens/logo-2.png"
            alt="Logo Compañero Chamex"
          ></img>
        </Row>
        <Row className="justify-content-center mt-5 mb-5">
          {uuid && uuidValidado && formNovaSenha}
          {uuid && !uuidValidado && validandoUuid}
          {!uuid && formEmailRut}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
