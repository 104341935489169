export const formatCelular = (valor) => {
  if (!valor) return '';
  return `(${valor.substring(0, 3)}) ${valor.substring(3, 6)}-${valor.substring(
    6,
    12
  )}`;
};

export const formatMilha = (valor) => {
  if (typeof valor !== 'number') return valor;
  
  valor = parseInt(valor).toLocaleString('pt-BR', {
    useGrouping: true,
    
  });

  return valor;
};
