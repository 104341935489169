import React from 'react';
import { Table, Modal } from 'react-bootstrap';

export default function ModalItensPontuacao(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Itens</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Table className="dash-table" responsive variant="dark">
          <thead className="dash-table-header">
            <tr>
              <th>Código</th>
              <th>Produto</th>
              <th>Qtde</th>
              <th>Valor Unitário</th>
              <th>Valor Desconto</th>
              <th>Subtotal</th>
              <th>Valor Pontos</th>
            </tr>
          </thead>
          <tbody className="dash-table-body">
            {props.produtos.map((produto) => (
              <tr key={produto.id}>
                <td>{produto.codigo}</td>
                <td>{produto.descricao}</td>
                <td>{Number(produto.qtde).toLocaleString('pt-BR')}</td>
                <td>
                  {Number(produto.valorUnitario)
                    .toLocaleString('es-PY', {
                      style: 'currency',
                      currency: 'PYG',
                    })
                    .toString()
                    .replace(/\u00a0/g, ' ')}
                </td>
                <td>
                  {Number(produto.valorDesconto)
                    .toLocaleString('es-PY', {
                      style: 'currency',
                      currency: 'PYG',
                    })
                    .toString()
                    .replace(/\u00a0/g, ' ')}
                </td>
                <td>
                  {(
                    Number(produto.valorUnitario) * Number(produto.qtde) -
                    Number(produto.valorDesconto)
                  )
                    .toLocaleString('es-PY', {
                      style: 'currency',
                      currency: 'PYG',
                    })
                    .toString()
                    .replace(/\u00a0/g, ' ')}
                </td>
                <td>{Number(produto.valorPontos).toLocaleString('pt-BR')}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={5} className="font-weight-bold text-right">
                Total
              </td>
              <td colSpan={2}>
                {props.produtos
                  .reduce(
                    (acc, item) =>
                      Number(acc) +
                      (Number(item.qtde) * Number(item.valorUnitario) -
                        Number(item.valorDesconto)),
                    0
                  )
                  .toLocaleString('es-PY', {
                    style: 'currency',
                    currency: 'PYG',
                  })
                  .toString()
                  .replace(/\u00a0/g, ' ')}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
