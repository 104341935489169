import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ConfimarEndereco = ({
  state,
  premio,
  qtde,
  resgatar,
  setConfirmar,
  pontosBase,
}) => {
  const [open, setOpen] = useState('ENDERECO');
  const { enderecoEntrega } = state;
  const [completo, setCompleto] = useState(
    enderecoEntrega.entregaRua &&
      enderecoEntrega.entregaCidade &&
      enderecoEntrega.entregaNumero &&
      enderecoEntrega.entregaNome
  );

  const [data, setData] = useState({
    entregaRua: enderecoEntrega.entregaRua,
    entregaCidade: enderecoEntrega.entregaCidade,
    entregaNumero: enderecoEntrega.entregaNumero,
    entregaComplemento: enderecoEntrega.entregaComplemento,
    entregaNome: enderecoEntrega.entregaNome,
  });

  return (
    <>
      {open === 'ENDERECO' && !completo && (
        <Form
          className="form"
          onSubmit={(e) => {
            setData(e);
            setOpen('CONFIMAR');
          }}
        >
          <Row>
            <span className="p-2">
              <strong>Complete los detalles de la dirección de entrega.</strong>
            </span>
          </Row>
          <Row className="d-flex align-items-center">
            <Col xs={12} md={12} className="p-1">
              <label htmlFor="entrega">Entregar a</label>
              <Input
                id="entregaNome"
                name="entregaNome"
                required
                type="text"
                className="w-100"
                placeholder="¿Quién recibirá el premio?"
                onChange={(e) => {
                  setData({ ...data, entregaNome: e.target.value });
                }}
                value={data.entregaNome}
              ></Input>
            </Col>
            <Col xs={12} md={12} className="p-1">
              <label htmlFor="entregaRua">Dirección</label>
              <Input
                id="entregaRua"
                name="entregaRua"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaRua: e.target.value });
                }}
                value={data.entregaRua}
              ></Input>
            </Col>
            <Col xs={12} md={2} className="p-1">
              <label htmlFor="entregaNumero">Numero</label>
              <Input
                id="entregaNumero"
                name="entregaNumero"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaNumero: e.target.value });
                }}
                value={data.entregaNumero}
              ></Input>
            </Col>
            <Col xs={12} md={10} className="p-1">
              <label htmlFor="entregandBairroirro">Complemento</label>
              <Input
                id="entregaComplemento"
                name="entregaComplemento"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaComplemento: e.target.value });
                }}
                value={data.entregaComplemento}
              ></Input>
            </Col>

            <Col xs={12} md={12} className="p-1">
              <label htmlFor="entregaCidade">Ciudad</label>
              <Input
                id="entregaCidade"
                name="entregaCidade"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaCidade: e.target.value });
                }}
                value={data.entregaCidade}
              ></Input>
            </Col>
          </Row>
          <Row>
            <button className="btn-resgatar" type="submit">
              <i className="fas fa-shopping-cart"></i> Confimar
            </button>
          </Row>
        </Form>
      )}
      {(open === 'CONFIMAR' || completo) && (
        <>
          <div>
            <span className="p-2 text-center">
              ¿Está seguro de que desea canjear{' '}
              <span className="red">
                <b>{`${qtde} x ${premio.nome}`}</b>
              </span>{' '}
              por{' '}
              <span className="red">
                <b>{Number(pontosBase * qtde).toLocaleString('pt-BR')}</b>{' '}
                puntos?{' '}
              </span>
            </span>
          </div>
          <div className="m-4 text-center">
            <span style={{ fontSize: '18px' }} className="p-2">
              Entregar a <strong>{data.entregaNome}</strong>
              <br />
            </span>
            <span className="p-2">
              {data.entregaRua} , {data.entregaNumero} ,{' '}
              {data.entregaComplemento}
              <br />
            </span>
            <span className="p-2">{data.entregaCidade}</span>
            <div style={{ width: '100%' }}>
              <button
                className="btn btn-light m-2"
                style={{ backgroundColor: '#dddddd' }}
                onClick={() => {
                  setOpen('ENDERECO');
                  setCompleto(false);
                }}
              >
                CAMBIAR DIRECCIÓN{' '}
              </button>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-success m-2"
              onClick={() => resgatar(data)}
            >
              <i className="fas fa-check"></i> CONFIMAR
            </button>
            <button
              className="btn btn-danger m-2"
              onClick={() => setConfirmar(false)}
            >
              <i className="fas fa-times"></i> NO
            </button>
          </div>
        </>
      )}
    </>
  );
};
