import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function UploadFoto() {
  const [enviando, setEnviando] = useState(false);
  const [arquivos, setArquivos] = useState(null);
  const [status, setStatus] = useState('Buscar...');

  const upload = async (e) => {
    e.preventDefault();

    if (!arquivos) {
      toast.error(`Selecione um arquivos primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < arquivos.length; i++) {
      formData.append('arquivos', arquivos[i]);
    }
    setEnviando(true);
    const resultado = await api.post('/api/upload/venda_foto', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success(
        `${Number(resultado.data.total).toLocaleString(
          'pt-BR'
        )} archivo(s) enviado(s) correctamente`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      setArquivos(null);
      setStatus('Buscar...');
    }
    setEnviando(false);
  };

  useEffect(() => {
    if (!arquivos) {
      document.getElementById('arquivos').value = null;
    }
  }, [arquivos]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>MANDAR PUNTUACIÓN</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <form onSubmit={upload} className="form fundo-claro">
          <label htmlFor="arquivos">Foto</label>
          <input
            type="file"
            id="arquivos"
            name="arquivos"
            multiple
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivos(e.target.files);
              e.target.files.length > 0
                ? setStatus(
                    `${e.target.files.length} arquivos(s) selecionado(s)`
                  )
                : setStatus('Buscar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivos').click();
            }}
          />
          <Row className="d-flex justify-content-center align-items-center">
            {enviando ? (
              <button disabled className="btn-enviar" type="submit">
                MANDANDO...
              </button>
            ) : (
              <button className="btn-enviar" type="submit">
                MANDAR
              </button>
            )}
          </Row>
        </form>
      </div>
    </Container>
  );
}
