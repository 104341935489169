import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { useSelector } from 'react-redux';

export default function Navbar() {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  return (
    <nav className="nav w-100 d-flex justify-content-center align-items-center">
      <Link className="nav-link" to="/loja">
        Home
      </Link>
      <Link className="nav-link" to="/perfil">
        Mi Perfil
      </Link>
      <Link className="nav-link" to="/pontuacoes">
       Puntuaciones
      </Link>
      <Link className="nav-link" to="/resgates">
        Canje
      </Link>
      {/* <Link className="nav-link" to="/cartao">
        Cartão
  </Link> */}
      {parceiro.tipoPontuacaoRevenda ? (
        <Link className="nav-link" to="/enviar-pontuacao">
          Mandar Puntuación
        </Link>
      ) : null}
      <Link className="nav-link" to="/pontos-produtos">
        Puntos por Producto
      </Link>
      <Link className="nav-link" to="/regulamento">
        Reglamento
      </Link>
      <Link className="nav-link" to="/contato">
        Contacto
      </Link>
    </nav>
  );
}
